import { logger } from '@/utils/logger'
import { useEffect, useRef, useState } from 'react'

function useVersionChecker() {
	const [_currentVersion, setCurrentVersion] = useState<string | null>(null)
	const intervalIdRef = useRef<number | null>(null)
	const versionRef = useRef<string | null>(null)

	useEffect(() => {
		const checkVersion = async () => {
			logger.info('Checking for new deploy version...')
			try {
				const res = await fetch('/api/has-new-deploy', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
					cache: 'no-store',
				})

				if (!res.ok) {
					throw new Error(`HTTP error! status: ${res.status}`)
				}

				const { version } = await res.json()
				logger.info('Fetched version:', version)

				if (!versionRef.current) {
					versionRef.current = version
					setCurrentVersion(version)
					logger.info('Initial version set to:', version)
				} else if (versionRef.current !== version) {
					logger.info(
						`Version checker found upgrade from ${versionRef.current} to ${version} and is reloading`,
					)
					window.location.reload()
				}
			} catch (error) {
				logger.error('Error checking app version:', error)
			}
		}

		checkVersion()
		intervalIdRef.current = window.setInterval(checkVersion, 60000) // Still set to 1000ms for testing
		logger.info('Version checker interval set up')

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
				logger.info('Version checker interval cleared')
			}
		}
	}, []) // Empty dependency array

	return null
}

export default useVersionChecker
