'use client'
import { logger } from '@/utils/logger'
import { supabaseBrowserInstance } from '@/lib/supabaseBrowser'
import type { DatabaseRow } from '@/types/database.table.types'
import type { Session, User } from '@supabase/supabase-js'
import { usePathname } from 'next/navigation'
import { type ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { fetchCrewMemberByUserId } from '@/queries/crewMemberQueries'
import { fetchTeamById } from '@/queries/teamQueries'

interface AuthContextType {
	user: User | undefined | null
	teamId: string
	team: DatabaseRow<'teams'> | undefined
	session: Session | null
	actingAsCrewMember: DatabaseRow<'crew_members'> | null
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthProviderProps {
	children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | undefined | null>(undefined)
	const [team, setTeam] = useState<DatabaseRow<'teams'> | undefined>(undefined)
	const [actingAsCrewMember, setActingAsCrewMember] = useState<DatabaseRow<'crew_members'> | null>(
		null,
	)
	const [session, setSession] = useState<Session | null>(null)

	const pathname = usePathname()

	useEffect(() => {
		const checkSession = async () => {
			const {
				data: { session },
			} = await supabaseBrowserInstance.auth.getSession()
			setSession(session)
			setUser(session?.user || null)
		}
		void checkSession()
	}, [])

	useEffect(() => {
		const handleAuthChange = (event: string, newSession: Session | null) => {
			if (pathname) {
				logger.info(`🔒 Auth event: ${event}`)
				setSession(newSession)
				setUser(newSession?.user || null)
			}
		}

		const { data: authListener } = supabaseBrowserInstance.auth.onAuthStateChange(handleAuthChange)

		return () => {
			authListener?.subscription.unsubscribe()
		}
	}, [pathname])

	useEffect(() => {
		const fetchActingAsCrewMember = async () => {
			if (user) {
				const crewMember = await fetchCrewMemberByUserId(supabaseBrowserInstance, user.id)
				setActingAsCrewMember(crewMember)
			}
		}
		void fetchActingAsCrewMember()
	}, [user])

	useEffect(() => {
		const fetchTeam = async () => {
			if (actingAsCrewMember) {
				const team = await fetchTeamById(supabaseBrowserInstance, actingAsCrewMember.team_id)
				setTeam(team)
			}
		}
		void fetchTeam()
	}, [actingAsCrewMember])

	const value: AuthContextType = {
		user,
		teamId: actingAsCrewMember?.team_id || '',
		team,
		session,
		actingAsCrewMember,
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
	const context = useContext(AuthContext)
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider')
	}
	return context
}
